﻿// TODO: GOTHAM BOOK + GOTHAM BOLD

@each $font-face in Gotham-Bold, Gotham-Book {
    @font-face {
        font-family: $font-face;
        font-display: swap;
        font-style: normal;
        src: local(#{$font-face}), url('#{$base-font-path}gotham/#{$font-face}.woff2') format('woff2'), url('#{$base-font-path}gotham/#{$font-face}.woff') format('woff'), url('#{$base-font-path}gotham/#{$font-face}.ttf') format("truetype");
    }
}
