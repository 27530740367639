﻿.btn {
    border: 0;
    background-color: transparent;
    @include font-size($font-size-button);
    @include border-button;
    height: 40px;
    position: relative;

    &--primary {
        color: $white;
        background-color: $brand-blue;

        &.btn-small {
            height: 32px;
            line-height: 0.5rem;
        }

        &.btn-add, &.btn-icon {
            height: 32px;
            line-height: 0.5rem;
            @include font-size($font-size-icon-large);
        }

        &:hover {
            color: $white;
            background-color: $brand-orange;
        }

        &.btn-upload {
            line-height: 2;
        }
    }

    &--secondary {
        background-color: $btn-secondary;

        &:hover {
            color: $black-with-opacity;
        }
    }

    &--tertiary {
        background-color: $brand-orange;
        color: white;
        height: 32px;
        line-height: 0.5rem;

        &:hover {
            color: $black-with-opacity;
        }
    }
}
