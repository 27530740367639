﻿.modal {
    .modal-dialog {
        .modal-content {
            @include border-large;

            .modal-header, .modal-body, .modal-footer {
                padding: 1.5rem 1.5rem;
            }

            .modal-header {
                border-bottom: 0;
                padding-bottom: 0;

                .modal-title {
                    margin-bottom: 0;
                    line-height: 1.5;
                }

                .close {
                    @include font-size($font-size-icon-x-large);
                }
            }

            .modal-footer {
                border-top: 0;
                padding-top: 0;
            }
        }
    }
}