﻿.block {
    background-color: $white;
    position: relative;
    margin: 2.5rem 0;

    &__header {
        position: relative;
        margin-bottom: 3rem;

        .title {
            @include font-size($font-size-medium);
            line-height: 1.2;
            margin-bottom: 0;
        }

        .subtitle {
            line-height: 1.2;
            margin-bottom: 0;
            @include font($font-regular);
            @include font-size($font-size-regular);
        }
    }

    @media (min-width: $breakpoint-md) {
        @include border-large;
        padding: 2rem;
        box-shadow: 0 2px 15px rgba(0,0,0,.3);

        &__header {
            .title {
                @include font-size($font-size-x-large);
            }

            .subtitle {
                @include font-size($font-size-medium);
            }
        }
    }
}
