﻿/// --------------------------------------------------------------------------------------------------------
/// This file contains the project specific variables. This is an extension to the basesource variables
/// --------------------------------------------------------------------------------------------------------
// Asset helpers
$custom-base-path: '../../../' !default;
$custom-font-path: $custom-base-path + 'fonts' + '/' !default;

// Layout sizing
$navbar-height: 100px;
$footer-height: 100px;
$footer-height-mobile: 140px;
// Extending Bootstrap _embed.scss
$embed-responsive-aspect-ratios: append((), (3 2));

/// Fonts
/// You can extend the default fonts here.
/// -------------------------
// Fonts
$font-regular: "Gotham-Book", Verdana, 'Helvetica Neue', sans-serif;
$font-bold: "Gotham-Bold", Roboto, 'Helvetica Neue', sans-serif;


/// Font sizing
/// Use this in combination with:
/// @include font-size($font-size-reg)
$font-size-tiny: 12px;
$font-size-small: 14px;
$font-size-regular: 16px;
$font-size-medium: 18px;
$font-size-large: 24px;
$font-size-x-large: 32px;

$font-size-button: 16px;
$font-size-icon-medium: 18px;
$font-size-icon-large: 20px;
$font-size-icon-x-large: 24px;

// $font-awesome: "Font Awesome 5 Free";

// Z-index


/// Colors
/// You can extend the default colors here.
$brand-blue: #1d1160;
$brand-orange: #f47c30;
$brand-red: #E63233;
$brand-green: #11C96D;

$bg-overlay: #000F1A;

$white-with-opacity: rgba(255,255,255,.5);
$black-with-opacity: rgba(0,0,0,.5);
$brand-blue-with-opacity: rgba(29,17,96,.5);

$btn-secondary: rgba(29, 17, 96, 0.2) ;

$input-bg: #FCFCFC;
$input-border-color: #E1E1E1;
$color-placeholder: #92AFCC;

$white: #fff;
$black: #000;
$color-qr: #F03B2C;