﻿.main-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: $footer-height-mobile;
    @include font-size($font-size-tiny);
    padding-top: 15px;
    background-color: $brand-blue;
    color: $white;

    .footer-nav {
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        &__item {
            &:not(:last-child) {
                margin-right: 15px;
            }
        }

        &__link {
            color: inherit;

            &:hover, &.active {
                text-decoration: underline;
            }
        }
    }

    @media (min-width: $breakpoint-lg) {
        height: $footer-height;
        padding-top: 0px;

        .footer-nav {
            justify-content: flex-end;
        }
    }
}

.copyright, .social-list{
    flex: 0 0 100%;
    max-width: 100%;
    display: flex;
    justify-content: center;
}

.copyright {
    @media (min-width: $breakpoint-lg) {
        justify-content: flex-start;
    }
}

.social-list {
    margin: 0;
    display: flex;
    justify-content: center;

    .social-item {
        margin-right: 0;

        &__link {
            width: 48px;
            height: 48px;
            background-color: $white;
            color: $black;
            display: flex;
            align-items: center;
            justify-content: center;
            @include font-size($font-size-regular);
            transition: background .2s ease;

            &:hover {
                text-decoration: none;
                color: $white;

                &.facebook {
                    background: $color-facebook;
                }

                &.instagram {
                    background: $color-instagram;
                }

                &.twitter {
                    background: $color-twitter;
                }

                &.linkedin {
                    background: $color-linkedin;
                }
            }
        }
    }

    @media (min-width: $breakpoint-lg) {
        justify-content: flex-start; 

        .social-item {
            &__link {
                width: 30px;
                height: 30px;
                @include font-size($font-size-small);
            }
        }
    }
}