﻿.ticket-page {
    .single-choice {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .custom-choice {
        flex: 0 0 100%;
        max-width: 100%;
        display: flex;
        margin-top: 15px;

        &__input {
            margin-left: 15px;
        }
    }

    .seller-text {
        padding: 2rem 2rem 1rem;
        margin-bottom: 20px;
        border: 4px solid $brand-blue;
        @include border-text;

        p {
            color: $brand-blue;
        }
    }

    .personalized-section {
        .image-container {
            text-align: center;
            width: 40%;
            margin: 0 auto 10px;

            img {
                display: block;
                max-width: 100%;
                height: auto;
            }
        }

        .text-center {
            h3 {
                margin-bottom: 10px;
            }

            .goal-text {
                padding: 1rem 2rem;
                margin-bottom: 20px;
                background-color: $brand-blue;
                border: 4px solid $brand-blue;
                //@include border-text;

                span {
                    color: $white;
                }
            }
        }

        &.header__content:after {
            margin-left: auto;
            margin-right: auto;
        }
    }

    .btn--primary {
        margin: 0 auto;
        height: 50px;

        span {
            background-color: $brand-green;
            padding: 0 30px;
        }
    }

    .custom-checkbox {
        padding-left: 2.6rem;
    }

    @media (min-width: $breakpoint-md) {
        .single-choice {
            flex: 0 0 auto;
        }

        .custom-choice {
            flex: 0 0 auto;
            margin-top: 0;
            margin-left: 15px;
            display: flex;

            &__input {
                margin-left: 15px;
            }
        }

        .personalized-section {
            .image-container {
                width: 20%;
            }
        }
    }
}
