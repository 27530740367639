﻿.insert-ticket-page {
    width: 100%;
    max-width: 100%;
    margin-bottom: calc(-#{$footer-height-mobile} - 50px); // 50px spacing from design
    @media (min-width: $breakpoint-lg) {
        margin-bottom: calc(-#{$footer-height} - 108px); // 108px spacing from design
    }

    .custom-choice {
        flex: 0 0 auto;
        margin-top: 0;
        margin-left: 15px;
        display: flex;

        &__input {
            margin-left: 15px;
        }
    }

    .btn {
        &--primary {
            margin: 0 auto;
            height: 50px;

            span {
                background-color: $brand-green;
                padding: 0 30px;
            }
        }
    }
}
