﻿h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    @include font($font-bold);
    line-height: 1.5;
    color: $brand-blue;
}

h1, .h1 {
    @include fluid-type($breakpoint-xs, $breakpoint-xl, 21px, 32px);
}

h2, .h2 {
    @include fluid-type($breakpoint-xs, $breakpoint-xl, 18px, 18px);
}

h3, .h3 {
    @include fluid-type($breakpoint-xs, $breakpoint-xl, 16px, 18px);
}

h4, .h4 {
    @include fluid-type($breakpoint-xs, $breakpoint-xl, 16px, 18px);
}

h5, .h5 {
    @include fluid-type($breakpoint-xs, $breakpoint-xl, 16px, 18px);
}

h6, .h6 {
    @include fluid-type($breakpoint-xs, $breakpoint-xl, 16px, 18px);
}

.tiny {
    @include font-size($font-size-tiny);
}

.small {
    @include font-size($font-size-small);
}

strong, .strong{
    @include font($font-bold);
}

em, .em{
    font-style: italic;
}