﻿/* The container */
.custom-radiobuttons {
    margin-top: 5px;
    .custom-radiobutton {
        display: inline-block;
        position: relative;
        padding: 0 10px 0 35px;
        margin-bottom: 10px;
        cursor: pointer;
        font-size: 1.6rem;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            &:checked ~ .checkmark {
                background-color: $brand-blue;
                &:after {
                    display: block;
                }
            }
        }
        &:hover input ~ .checkmark {
            background-color: #ccc;
        }
        .checkmark:after {
            top: 6px;
            left: 6px;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: white;
        }
    }
    .checkmark {
        position: absolute;
        top: 1px;
        left: 0;
        height: 20px;
        width: 20px;
        background-color: #eee;
        border-radius: 50%;
        &:after {
            content: "";
            position: absolute;
            display: none;
        }
    }
}