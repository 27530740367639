﻿.main-navigation-section {
    .navigation-background {
        background-color: $white;
        box-shadow: 0 2px 15px rgba(0,0,0,.3);
        padding: 1.5rem 0;

        .navbar-brand {
            width: 278px;
            height: 100%;
            padding: 0;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .account-text {
            margin-top: 1rem;
            padding-right: 1rem;
            color: $brand-blue;
        }
    }

    .navbar {
        background-color: $brand-blue;

        @media (min-width: 992px) {
            ul {
                &.navbar-nav {
                    .nav-link {
                        padding-left: 1rem;
                        padding-right: 1rem;
                    }

                    li:not(:last-child) {
                        border-right: 1px solid $white-with-opacity;
                        margin-right: 1rem;
                        padding-right: 1rem;
                    }
                }
            }
        }
    }
}
