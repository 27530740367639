﻿.content-wrapper {
    padding-bottom: 289px;
    position: relative;
    padding-bottom: calc(#{$footer-height-mobile} + 50px); // 50px spacing from design

    @media (min-width: $breakpoint-lg) {
        padding-bottom: calc(#{$footer-height} + 108px); // 108px spacing from design
    }
}

.page-background {
    background-color: $brand-blue-with-opacity;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;

    img {
        display: none;
    }
}

.page-container {
    position: relative;
    min-height: 100vh;

    &:before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
}

@media (min-width: $breakpoint-md) {
    .page-background {
        //background-image: image("bg-body-blue.jpg");
        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: bottom;
        }
    }

    .page-container {
        background-color: rgba($bg-overlay, 0.35);
    }
}


.personalized-section {
    .image-container {
        text-align: center;
        width: 40%;
        margin: 0 auto 10px;

        img {
            display: block;
            max-width: 100%;
            height: auto;
        }
    }

    .text-center {
        h3 {
            margin-bottom: 10px;
        }

        .goal-text {
            padding: 1rem 2rem;
            margin-bottom: 20px;
            background-color: $brand-blue;
            border: 4px solid $brand-blue;
            //@include border-text;

            span {
                color: $white;
            }
        }
    }

    &.header__content:after {
        margin-left: auto !important;
        margin-right: auto !important;
    }

    @media (min-width: $breakpoint-md) {
        .image-container {
            width: 20%;
        }
    }
}