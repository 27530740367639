﻿.responsive-table {
    width: 100%;
    overflow: hidden;

    tr {
        border-color: lighten(#34495E, 10%);
    }

    th, td:before {
        color: $black;
    }


    thead {
        &.thead-dark {
            th {
                background-color: $brand-blue;
            }
        }

        tr {
            border-bottom: 1px solid #ddd;
        }
    }

    tbody {

        .td {
            &__orderid {
            }

            &__scope {
            }

            &__created {
            }

            &__price {
            }

            &__state {
            }

            &__view, &__archive, &__edit {
                .btn {
                    @include font($font-regular);
                    color: $black;
                    line-height: 1;
                    text-decoration: underline;

                    &:hover {
                        color: darken($brand-blue, 10%);
                    }
                }
            }

            &__view {
            }

            &__archive {
            }
        }
    }

    th {
        display: none;
    }

    th, td:before {
        @include font($font-bold);
    }

    td {
        display: block;
        padding: 5px;

        &.break-words {
            word-break: break-word;
        }

        &:before {
            content: attr(data-th);
            width: 25%;
            display: inline-block;
        }

        &.table-actions {
            a {
                margin-left: 1rem;
            }
        }

        .cell-amount {
            width: 60px;
        }
    }

    th, td {
        text-align: left;
        padding: 5px 15px;
    }

    .ui-sortable {
        td {
            cursor: grab;
        }
    }

    @media (min-width: $breakpoint-lg) {
        td {
            &:before {
                display: none;
            }
        }

        th, td {
            display: table-cell;
        }
    }
}
