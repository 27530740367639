﻿input[type=checkbox] {
    cursor: pointer;
}

.pl-25 {
    padding-left: 2.5rem !important;
}

.checkbox-list {
    padding-left: 0;
    margin-left: 0;
    list-style: none;
}

.custom-control {
    padding-left: 2rem;
}

.custom-control-input {
    width: 2rem;
    height: 2rem;

    &:checked ~ .custom-control-label::before {
        background-color: $input-bg;
        border: 2px solid $brand-blue;
    }
}

.custom-control-label {
    &::before,
    &::after {
        width: 2rem;
        height: 2rem;
        left: -2.5rem;
    }

    &::before {
        background-color: $input-bg;
        border: 2px solid $brand-blue;
    }
}

// checkbox
.custom-checkbox {
    .custom-control-label {

        @include font-size($font-size-small);
        cursor: pointer;

        &::before {
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            border-bottom-left-radius: 5px;
            border-top-left-radius: 5px;
        }

        a:hover {
            text-decoration: underline;
            color: inherit;
        }
    }

    .custom-control-input {
        &:checked ~ .custom-control-label::after {
            background-image: image("checkmark.svg");
        }
    }
}

#editOverlayerModal .checkbox-list label {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}