﻿/// -----------------------------------------------------------------------------
/// This file contains very basic styles.
/// -----------------------------------------------------------------------------

/// Set up a decent box model on the root element
html {
    box-sizing: border-box;
    font-size: 62.5%;
}

/// * Make all elements from the DOM inherit from the parent box-sizing
/// * Since `*` has a specificity of 0, it does not override the `html` value
/// * making all elements inheriting from the root box-sizing value
/// * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
*,
*::before,
*::after {
    box-sizing: inherit;
}

body {
    &:before {
        content: 'extra-small';
        display: none;

        @media (min-width: $breakpoint-sm) {
            content: 'small';
        }

        @media (min-width: $breakpoint-md) {
            content: 'medium';
        }

        @media (min-width: $breakpoint-lg) {
            content: 'large';
        }

        @media (min-width: $breakpoint-xl) {
            content: 'extra-large';
        }
    }
}