﻿.gca-input-group {
    .result-list {
        width: calc(100% - 30px);
        list-style: none;
        position: absolute;
        padding: 0;
        left: 0;
        right: 0;
        z-index: 100;
        background-color: $white;
        margin-top: 5px;
        margin-left: auto;
        margin-right: auto;
        transition: all .1s ease-in-out;
        
        &__item {
            cursor: pointer;
            padding: 1rem;
            border-left: 1px solid $input-border-color;
            border-right: 1px solid $input-border-color;

            &:first-child {
                border-top: 1px solid $input-border-color;
            }

            &:last-child {
                border-bottom: 1px solid $input-border-color;
            }

            &:hover {
                background-color: #f2f2f2;
            }
        }
    }

    .btn{
        &--delete{
            width: 24px;
            height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            right: 2.5rem;
            top: .7rem;
            color: $brand-red;
        }
    }
}

.gca-input {
    width: 100%;
    @include font-size($font-size-regular);
    min-height: 40px;
    padding: 0 3.6rem 0 7.5px !important;
    border: 1px solid #dddddd;
    @include border-large;
    display: inline-flex;
    align-items: center;
    //background: $input-bg url(../../../img/select.svg) no-repeat right 1.5rem center/15px 9px;
    background: $input-bg no-repeat right 1.5rem center/15px 9px;
    outline: none;
    box-shadow: 0;
    transition: box-shadow .2s ease;
    @include placeholder($color-placeholder, $font-size-regular);

    &:focus {
        box-shadow: 0 0 3px rgba($brand-blue, 1);
    }
    // TODO: find out how we can add is active class
    &.is-active {
        border-top-right-radius: 2.4rem;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
    }
}
