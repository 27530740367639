﻿.form-control {
    height: 40px;
    padding: 0 15px;
    background-color: $input-bg;
    border: 1px solid $input-border-color;
    @include border-large;
    @include font-size($font-size-regular);
    @include placeholder($color-placeholder, $font-size-regular);
    font-weight: 300;
}
 
.form-control-amount{
    max-width: 110px;
} 