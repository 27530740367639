﻿.color-blue {
    color: $brand-blue;
}

.color-red {
    color: $brand-red;
}

.color-orange {
    color: $brand-orange;
}