﻿.share {
    &__list {
        margin-bottom: 0;
    }

    &__item {
        position: relative;
        width: 45px;
        height: 45px;
        border-radius: 50%;
        background-color: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        @include font-size($font-size-button);
        border: 1px solid;
        transition: all .2s ease;

        &:hover {
            text-decoration: none;
            color: $white;
        }

        &--whatsapp {
            border-color: $color-whatsapp;
            color: $color-whatsapp;

            &:hover {
                background-color: $color-whatsapp;
            }
        }

        &--facebook {
            border-color: $color-facebook;
            color: $color-facebook;

            &:hover {
                background-color: $color-facebook;
            }
        }

        &--mail {
            border-color: $black;
            color: $black;

            &:hover {
                background-color: $black;
            }
        }

        &--twitter {
            border-color: $color-twitter;
            color: $color-twitter;

            &:hover {
                background-color: $color-twitter;
            }
        }

        &--qr {
            border-color: $color-qr;
            color: $color-qr;

            &:hover {
                background-color: $color-qr;
            }
        }

        &--copy {
            border-color: $brand-blue;
            color: $brand-blue;

            &:hover {
                background-color: $brand-blue;
            }
        }
    }

    &__message {
        background-color: $brand-blue;
        @include font($font-regular);
        @include font-size($font-size-small);
        color: $white;
        pointer-events: none !important;
        padding: 5px;
        white-space: nowrap;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 0);
        opacity: 0;
        transition: all .4s cubic-bezier(0.28, 0.58, 0.46, 1.26);

        &:before {
            content: '';
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 7px 7.5px 0 7.5px;
            position: absolute;
            top: 100%;
            left: 50%;
            border-color: $brand-blue transparent transparent transparent;
            transform: translate(-50%, 0);
            transition: top .2s ease .13s;
        }

        &.is-active {
            bottom: calc(100% + 10px);
            opacity: 1;
            transition: all .4s cubic-bezier(0.28, 0.58, 0.46, 1.26);
        }
    }
}
