﻿// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------
html {}

body {
    @include font($font-regular);
    @include font-size($font-size-regular);
    background-color: $white; 
    color: $black;

    &.hide-overflow { 
        height: 100%;
        overflow: hidden;
    }
}