﻿
//  This mixin can be used to set the object-fit:
//  @include object-fit(contain);

//  or object-fit and object-position:
//  @include object-fit(cover, top);

@mixin object-fit($fit: fill, $position: null) {
    object-fit: $fit;

    @if $position {
        object-position: $position;
        font-family: 'object-fit: #{$fit}; object-position: #{$position}';
    }
    @else {
        font-family: 'object-fit: #{$fit}';
    } 
}


@mixin border-large() {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
    border-top-left-radius: 1rem;
}

@mixin border-button() {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    border-top-left-radius: 0.5rem;
}

@mixin drip-border() {
    border-top-right-radius: 4.8rem;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 4.8rem;
    border-top-left-radius: 4.8rem;
}

@mixin border-text() {
    border-top-right-radius: 2.4rem;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
}