﻿.block {
    .controls {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        .title, .share{
            text-align: center;
        }

        .title {
            flex: 0 0 100%;
            max-width: 100%;
            @include font-size($font-size-small);
            margin-bottom: 1.5rem;
        }

        .share {
            flex: 0 0 100%;
            max-width: 100%;
            margin-bottom: 1.5rem;
        }
    }

    @media (min-width: $breakpoint-lg) {
        .controls {
            display: flex;

            .title {
            }

            .share {
            }
        }
    }
}
