﻿// Grid breakpoints
// Helper to have different aspect ratio's on different breakpoints
@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        .embed-responsive#{$infix}-none{
            &:before{
                padding-top: 100vh;
            }
        }

        .embed-responsive#{$infix}-21by9 {
            &:before {
                padding-top: percentage(9 / 21);
            }
        }

        .embed-responsive#{$infix}-16by9 {
            &::before {
                padding-top: percentage(9 / 16); 
            }
        }

        .embed-responsive#{$infix}-4by3 { 
            &::before { 
                padding-top: percentage(3 / 4);
            }
        }

        .embed-responsive#{$infix}-1by1 {
            &::before {
                padding-top: percentage(1 / 1); 
            } 
        }

        .embed-responsive#{$infix}-3by2 {
            &::before {
                padding-top: percentage(2 / 3);
            }
        } 
    }
}