﻿.choices {
    &__inner {
        .choices__list.choices__list--single {
            .choices__item.choices__item--selectable {
                width: calc(100% - 16px) !important;
                overflow: hidden;
            }
        }
    }
}

.choices__list--dropdown .choices__item--selectable{
    padding-right: 10px !important;
}

.choices {
    position: relative;
    z-index: 10;

    &.is-open {
        z-index: 15;
    }

    &__placeholder {
        opacity: 1 !important;
    }


    &[data-type*='select-one']:after {
        display: none;
    }

    &__inner {
        @include font-size($font-size-regular);
        min-height: 40px;
        padding: 0 7.5px !important;
        @include border-large;
        display: inline-flex;
        align-items: center;
        background: $input-bg url(../../../img/select.svg) no-repeat right 1.5rem center/15px 9px;
        
        
        .choices__item.choices__placeholder{
            color: $color-placeholder;
        }
    }



    &__list {
        &--single {
            @include font-size($font-size-regular);
        }
    }

    &.is-open & {
        &__inner {
            background-color: $input-bg;
            border-top-right-radius: 2.4rem;
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
            background: #fff url(../../../img/select.svg) no-repeat right 1.5rem center/15px 9px;
        }
    }
}

.choices[data-type*='select-one'] .choices__button {
    background-image: url(../../../img/times.svg);
    background-repeat: no-repeat;
    background-size: 11px;
    right: 10px;
    opacity: 1;
}